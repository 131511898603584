body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mint-container {
  border-style: solid;
  border-width: 6px;
  border-color: #62574b;
  border-radius: 1px 50px 1px 50px;
  width: 200px;
  text-align: center;
  margin: auto;
  padding-top: 3%;
  padding-bottom: 5%;
  font-size: 1em;
  font-style: italic;

  }
.b-2 {
  background-image: url(https://cdn.discordapp.com/attachments/910644938981658695/910644992312242246/Base4.png);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position-x: 0mm;
  background-position-y: 32mm;

}